<template>
  <!-- 账号信息页面 -->
  <div>

    <div class="Account">
      <div class="Account_top">
        <div class="item_bock head_p">
          <div class="head_img">
            <img :src="userimg" />
          </div>
          <div class="setting_right" @click.stop="uploadHeadImg">
            <div class="caption">更改头像</div>
          </div>
          <input type="file" accept="image/*" @change="handleFile" class="hiddenInput" />
        </div>
      </div>
      <div class="Nickname">
        <div class="name_title">昵称：</div>
        <input type="text" class="ipts" :placeholder="nickname" v-model="usenames">
        <div class="modify" @click="modify_name">确认修改</div>
      </div>
      <div class="Nickname">
        <div class="name_title">用户ID：</div>
        <div class="Nickname_righjt">{{uid}}</div>
      </div>
      <!-- 手机 -->
      <div class="Account_bottom">
        <div class="Account_bottom_left">
          <img src="@/assets/img/iphone_icon.png" alt="">
          <div class="Account_bottom_left_n">手机</div>
          <div class="Account_bottom_left__t">已绑定 （{{phonestr}}）</div>
        </div>
        <div class="Account_bottom_right">
          <div class="Rebinding" @click="Go_ChangeBind()">重新绑定</div>
        </div>
      </div>
      <!-- 微信 -->
      <div class="Account_bottom">
        <div class="Account_bottom_left">
          <img src="@/assets/img/weixin_icon.png" alt="">
          <div class="Account_bottom_left_n">微信</div>
          <div class="Account_bottom_left__t">未绑定</div>
        </div>
        <div class="Account_bottom_right">
          <div class="Rebinding"
            style="width: 88px;height: 36px;background: #1F72D9;border-radius: 4px;color:#fff;text-align:center;line-height:36px;cursor: pointer;"
            @click="wxlogin">
            绑定</div>
        </div>
      </div>
      <!-- 密码 -->
      <div class="Account_bottom">
        <div class="Account_bottom_left">
          <img src="@/assets/img/mima_icon.png" alt="">
          <div class="Account_bottom_left_n">密码</div>
          <div class="Account_bottom_left__t">********（密码要求至少包含字母，符号或数字中 的两项且长度超过8位）</div>
        </div>
        <div class="Account_bottom_right">
          <div class="Rebinding" @click="Go_ChangePassword">修改密码</div>
        </div>
      </div>
      <div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
let token = localStorage.getItem("token");

export default {
   metaInfo(){
     return{
        title: '账号信息',
        meta: [
            {
                name: 'keywords',
                content: '鹰眼投诉_消费投诉维权平台,集体投诉,商家投诉,权威帮帮团,最新投诉报道,打假维权,质量曝光,客服咨询'
            },
            {
                name: 'description',
                content:'鹰眼投诉_消费投诉维权平台是驱动中国旗下的投诉平台,快速解决消费投诉,投诉维权,共享服务投诉,购物平台投诉,旅游出行投诉,住宿投诉,娱乐生活投诉,教育培训投诉,金融支付投诉等,拥有海量企业库,各领域专家,专业律师团队及权威帮帮团来帮助消费者。'
            }
        ]
     }     
    },
  data() {
    return {
      userimg: localStorage.getItem("headimgurl"),
      token: localStorage.getItem("token"),
      userInfo: {
        avatar:
          "https://weixin.qudong.com/public/app_upload/head_60dc1bd38366c7.61398143.png",
      },
      headimgurl: "",
      usenames: "", //用户昵称
      nickname: localStorage.getItem("nickname"),
      uid: localStorage.getItem("uid"),
      user_information: {},
      phonestr: "",
    };
  },
  methods: {
    wxlogin() {
      let AppID = "wx2f7f624dfc37ef9e";
      let redirect_uri = encodeURIComponent("https://www.zcool.com.cn/");
      //
      window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=wx2f7f624dfc37ef9e&redirect_uri=https://www.zcool.com.cn/?appId=1006&response_type=code&scope=snsapi_login&state=STATE84320`;
    },

    // 去修改密码页面
    Go_ChangePassword() {
      this.$router.push({ path: "/PersonalCenter/ChangePassword" });
    },

    uploadHeadImg: function () {
      this.$el.querySelector(".hiddenInput").click();
    },
    // 将头像显示
    handleFile: function (e) {
      var that = this;
      let $target = e.target || e.srcElement;
      let file = $target.files[0];
      var reader = new FileReader();
      reader.onload = (data) => {
        let res = data.target || data.srcElement;
        this.userimg = res.result;
        Axios.post("https://weixin.qudong.com/api/upload/headimg", {
          token: token,
          headimg: this.userimg.substring(22),
        }).then(function (res) {
          if (res.data.code == 200) {
            that.$message.success("修改图片成功");
            //console.log(res);
            let headimgurl = localStorage.setItem("headimgurl", res.data.data);
            headimgurl = res.data.data;
            //console.log(headimgurl);
          }
        });
      };
      reader.readAsDataURL(file);
    },

    // 修改昵称
    modify_name() {
      var that = this;
      Axios.post("https://weixin.qudong.com/api/user/nickname", {
        token: token,
        nickname: this.usenames,
      }).then(function (res) {
        if (res.data.code == 200) {
          //console.log(res.data.data.nickname);
          let nickname = localStorage.setItem(
            "nickname",
            res.data.data.nickname
          );
          nickname = nickname;
          that.$message.success("修改昵称成功");
          // //console.log(res);
          // let headimgurl = localStorage.setItem("headimgurl", res.data.data);
          // headimgurl = res.data.data;
          // //console.log(headimgurl);
        }
      });
    },
    // 跳转绑定手机页面
    Go_ChangeBind() {
      this.$router.push({
        path: "/PersonalCenter/ChangeBind",
        // query:{item:JS_item}
      });
    },
    plusXing(str, frontLen, endLen) {
      var len = str.length - frontLen - endLen;
      var xing = "";
      for (var i = 0; i < len; i++) {
        xing += "*";
      }
      this.phonestr =
        str.substr(0, frontLen) + xing + str.substr(str.length - endLen);
    },
  },
  mounted() {
    let user_information = localStorage.getItem("user_information");
    this.user_information = JSON.parse(user_information);
    this.plusXing(this.user_information.phone, 3, 4);
  },
};
</script>

<style scoped>
.Account {
  width: 910px;
  background: #fff;
  margin-left: 20px;
  padding: 32px;
}
/* 正面 */
.avatar-uploader_zheng {
  width: 104px;
  height: 104px;
  border-radius: 6px;
  margin-left: 57px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 50%;
  margin: 0 auto;
}
.avatar-uploader_zheng .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader_zheng .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon_zheng {
  font-size: 28px;
  color: #8c939d;
  width: 104px;
  height: 104px;
  line-height: 160px;
  border-radius: 50%;
  text-align: center;
}
.avatar_zheng {
  width: 104px;
  height: 104px;
  border-radius: 50%;
  display: block;
  background: #fff;
}
.el-icon-plus:before {
  content: none;
}
.Nickname {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.name_title {
  width: 100px;
  text-align: right;
}
.ipts {
  margin-left: 50px;
  width: 232px;
  height: 40px;
  border: 1px solid #999999;
  border-radius: 5px;
  padding-left: 15px;
}
.modify {
  color: #1f72d9;
  font-size: 16px;
  cursor: pointer;
  margin-left: 20px;
}
.Nickname_righjt {
  margin-left: 50px;
  font-size: 16px;
  color: #999999;
  font-weight: 500;
}
.Account_bottom {
  display: flex;
  margin-top: 20px;
}
.Account_bottom_left {
  display: flex;
  align-items: center;
  /* background:red; */
  width: 75%;
}
.Account_bottom_right {
  width: 25%;
  /* background:yellow; */
  display: flex;
  align-items: center;
}
.Account_bottom_left_n {
  margin-left: 20px;
}
.Account_bottom_left__t {
  width: 350px;
  margin-left: 60px;
  color: #999999;
}
.Rebinding {
  color: #1f72d9;
  cursor: pointer;
}

.item_bock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 94px;
  width: 250px;
  padding: 0px 24px 0px 38px;
  border-bottom: 1px solid #f7f7f7;
  background: #fff;
}
.head_p {
  height: 132px;
}
.head_img {
  height: 90px;
}
.head_img img {
  width: 90px;
  height: 90px;
  border-radius: 50px;
}
.setting_right {
  display: flex;
  height: 37px;
  justify-content: flex-end;
  align-items: center;
}
.hiddenInput {
  display: none;
}
.caption {
  color: #8f8f8f;
  font-size: 26px;
  height: 37px;
  cursor: pointer;
}
</style>